import * as styles from "../authentication.module.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { IconButton, InputAdornment, TextField} from "@mui/material";
import { Link } from "gatsby";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { I18n } from "react-redux-i18n";
import { loginSchema } from "../schema";
import { login, loginAdmin, verify2FALogin } from "../../../redux/actions/authActions";
import { AppDispatch } from "../../../redux/store";
import {
  commonFormBase,
  muiStylesAuthentication,
} from "../muiStylesAuthentication";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import { selectAuthUserError } from "../../../redux/selectors/authSelectors";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  handleNavigateExpiredPasswordPage,
  resetPasswordGetEmailPath,
} from "../../../utils/paths";
import PasswordExpiredPopup from "../../Common/Popup/PasswordExpiredPopup";
import TwoFactorPopup from "../../Common/Popup/TwoFactorPopup";
import ReCAPTCHA from "react-google-recaptcha";
import { reformatMessageToKey } from "../../../utils/redux";
import { useIsCapsLockOn } from "../../../utils/hooks";
import { LoginData, LoginError, parseJsonMessage, isMessageArray } from "../../../api/loginTypes";

interface Props {
  adminLogin?: boolean | undefined;
}

const Login: FC<Props> = ({ adminLogin }) => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [twoFactorDialogOpen, setTwoFactorDialogOpen] = useState<boolean>(false);
  const [twoFactorCode, setTwoFactorCode] = useState<string>("");
  const [backupCode, setBackupCode] = useState<string>("");
  const [twoFactorError, setTwoFactorError] = useState<string>("");
  const [showBackupCode, setShowBackupCode] = useState<boolean>(false);
  const [loginCredentials, setLoginCredentials] = useState<LoginData | null>(null);
  const capsLock = useIsCapsLockOn();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showActivityErrMsg, setShowActivityErrMsg] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();
  const loginError = useSelector(selectAuthUserError);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const expiredUserId = useRef<string | undefined>(undefined);
  const [tempToken, setTempToken] = useState<string | null>(null);

  const checkActivity = () => {
    const { details } = loginError;
    if (
      typeof details === "object" &&
      !Array.isArray(details) &&
      details !== null
    ) {
      if (Object.prototype.hasOwnProperty.call(details, "message")) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const msg: string[] = details.message;
        if (Array.isArray(msg)) {
          if (msg[0].includes("Laboratory is inactive or deleted.")) {
            setShowActivityErrMsg(I18n.t("Login.Laboratory_is_inactive_or_deleted"));
            return false;
          }
          if (msg[0].includes("User account is not active.")) {
            setShowActivityErrMsg(I18n.t("Login.User_account_is_not_active"));
            return false;
          }
          if (msg[0].includes("User account has been deleted.")) {
            setShowActivityErrMsg(I18n.t("Login.User_account_has_been_deleted"));
            return false;
          }
        }
      }
    }

    if (isPasswordExpired()) {
      setOpenPopup(true);
      setShowActivityErrMsg("");
      return false;
    }

    return true;
  }
  const displayLoginErrorMsg = () => {
    let errMsg = showActivityErrMsg;
    if (showActivityErrMsg === "") {
      errMsg = loginError.message !== "Validation failed"
        ? I18n.t(
          `Login.${reformatMessageToKey(
            loginError?.message as string
          )}`
        )
        : I18n.t("Login.error")
    }

    return errMsg;
  }
  const isPasswordExpired = () => {
    const { details } = loginError;

    // check if errorDetail is a non-null object
    if (
      typeof details === "object" &&
      !Array.isArray(details) &&
      details !== null
    ) {
      // check if errorDetail has `message` key
      if (Object.prototype.hasOwnProperty.call(details, "message")) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const msg: string[] = details.message;

        // check if errorDetail.message is an array
        if (isMessageArray(msg) && msg.length > 0) {
          const parsedMsg = parseJsonMessage(msg[0]);
          if (parsedMsg?.msg?.includes("Password expired")) {
            // Convert id to string if it exists
            if (parsedMsg.id !== undefined) {
              expiredUserId.current = String(parsedMsg.id);
            }
            return true;
          }
          // Fallback to string check if parsing didn't yield expected format
          return msg[0].includes("Password expired");
        }
      }
    }

    return false;
  };

  const is2FARequired = (): boolean => {
    // First check error details
    const { details } = loginError;
    if (details?.requires2fa === true) {
      return true;
    }

    // Check if details contains a message array
    const detailsMessages = details?.message;
    if (isMessageArray(detailsMessages) && detailsMessages.length > 0) {
      const detailsParsedMessage = parseJsonMessage(detailsMessages[0]);
      if (detailsParsedMessage?.requires2fa === true) {
        return true;
      }
    }

    // Then try parsing the direct message
    const messages = loginError.message;
    if (isMessageArray(messages) && messages.length > 0) {
      const messageParsedMessage = parseJsonMessage(messages[0]);
      if (messageParsedMessage?.requires2fa) {
        return true;
      }
    }

    return false;
  };

  const getTempToken = (): string | null => {
    // First check error details
    const { details } = loginError;
    if (typeof tempToken === "string") {
      return tempToken;
    }

    // Check if details contains a message array
    const detailsMessages = details?.message;
    if (isMessageArray(detailsMessages) && detailsMessages.length > 0) {
      const parsedMessage = parseJsonMessage(detailsMessages[0]);
      if (typeof parsedMessage?.tempToken === "string") {
        return parsedMessage.tempToken;
      }
      if (parsedMessage?.id !== undefined) {
        return String(parsedMessage.id);
      }
    }

    // Then try parsing the direct message
    const messages = loginError.message;
    if (isMessageArray(messages) && messages.length > 0) {
      const parsedMessage = parseJsonMessage(messages[0]);
      if (typeof parsedMessage?.tempToken === "string") {
        return parsedMessage.tempToken;
      }
      if (parsedMessage?.id !== undefined) {
        return String(parsedMessage.id);
      }
    }

    return null;
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onTouched",
    resolver: yupResolver(loginSchema),
  });

  const handleLogin = async (data: LoginData, twoFactorToken?: string, useBackupCode?: string) => {
    const captchaToken = await recaptchaRef.current?.executeAsync() ?? null;

    const loginData = {
      email: data.email.toLowerCase(),
      captchaToken,
      password: data.password,
      ...(twoFactorToken && { token: twoFactorToken }),
      ...(useBackupCode && { backupCode: useBackupCode }),
    };

    await dispatch(
      adminLogin ? loginAdmin(loginData) : login(loginData)
    );

    recaptchaRef.current?.reset();
  };

  const onSubmit = handleSubmit(async (data: LoginData) => {
    setLoginCredentials(data);
    await handleLogin(data);
    // Check if 2FA is required after login attempt
    if (loginError && is2FARequired()) {
      setTwoFactorDialogOpen(true);
    }
  });

  const handleTwoFactorSubmit = async () => {
    // Get temp token from state or try to get a new one
    const currentTempToken = tempToken || getTempToken();
    if (!currentTempToken) {
      setTwoFactorError(I18n.t("TwoFactor.verificationError"));
      return;
    }

    // Store the temp token for subsequent attempts
    if (!tempToken) {
      setTempToken(currentTempToken);
    }

    setTwoFactorError("");
    try {
      await dispatch(verify2FALogin({
        tempToken: currentTempToken,
        twoFactorToken: showBackupCode ? backupCode : twoFactorCode,
        isBackupCode: showBackupCode
      })).unwrap();
      // If we get here, the action was successful
      setTwoFactorDialogOpen(false);
      // Clear the stored temp token
      setTempToken(null);
    } catch (err) {
      // Try to get the error message from the response
      let errorMessage = I18n.t("TwoFactor.verificationError");
      if (err instanceof Error) {
        errorMessage = err.message || errorMessage;
      } else if (typeof err === "object" && err !== null) {
        const errorResponse = err as LoginError;
        if (errorResponse.details?.message) {
          errorMessage = errorResponse.details.message[0] || errorMessage;
        }
      }
      setTwoFactorError(errorMessage);
    }
  };

  useEffect(() => {
    if (loginError && is2FARequired()) {
      setTwoFactorDialogOpen(true);
    }
  }, [loginError]);

  useEffect(() => {
    showActivityErrMsg === "" && checkActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginError.details]);

  return (
    <>
      <div>
        <form onSubmit={onSubmit} className={styles.loginForm}>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={
              (process.env.PUBLIC_RECAPTCHA_SITE_KEY as string) || "invalid key"
            }
          />
          {adminLogin && <h1>{I18n.t("Login.adminHeader")}</h1>}
          {loginError.error && !isPasswordExpired() && !is2FARequired() && (
            <p className={styles.loginError}>
              {displayLoginErrorMsg()}
            </p>
          )}
          <Controller
            name="email"
            render={({ field }) => (
              <TextField
                autoComplete="off"
                variant="outlined"
                error={!!errors.email}
                label={I18n.t("FormLabels.email")}
                type="text"
                sx={muiStylesAuthentication.inputEmail}
                inputProps={{
                  style: muiStylesAuthentication.inputEmail
                    .lowercase as React.CSSProperties,
                }}
                {...field}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            control={control}
          />
          <Controller
            name="password"
            render={({ field }) => (
              <TextField
                label={I18n.t("FormLabels.password")}
                sx={muiStylesAuthentication.inputPassword}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                error={!!errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        sx={commonFormBase.passwordVisibility}
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            control={control}
          />
          <div className={styles.loginMessagesContainer}>
            {capsLock && (
              <div className={styles.capslockContainer}>
                <ErrorOutlineOutlinedIcon fontSize="small" />
                <h3 className={styles.h3TypographyError}>{I18n.t("Login.warning")}</h3>
              </div>
            )}

            <Link
              className={styles.loginNavigateLink}
              to={resetPasswordGetEmailPath}
            >
              {I18n.t("Login.reset")}
            </Link>
          </div>

          <PrimaryButton text={I18n.t("Buttons.login")} loginBtn isSubmit />
        </form>

        <TwoFactorPopup
          isOpen={twoFactorDialogOpen}
          onClose={() => setTwoFactorDialogOpen(false)}
          onSubmit={handleTwoFactorSubmit}
          twoFactorCode={twoFactorCode}
          backupCode={backupCode}
          twoFactorError={twoFactorError}
          showBackupCode={showBackupCode}
          onTwoFactorCodeChange={(value) => setTwoFactorCode(value)}
          onBackupCodeChange={(value) => setBackupCode(value)}
          onToggleBackupCode={() => setShowBackupCode(!showBackupCode)}
        />
      </div>

      <PasswordExpiredPopup
        isOpen={openPopup}
        onAcceptButton={() => handleNavigateExpiredPasswordPage(expiredUserId.current ? expiredUserId.current : "")}
      />
    </>
  );
};

export default Login;
