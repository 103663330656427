export interface LoginData {
  email: string;
  password: string;
}

export interface LoginDetails {
  message?: string[];
  requires2fa?: boolean;
  tempToken?: string;
}

export interface LoginError {
  details?: LoginDetails;
  message?: string[] | string;
  error?: boolean;
  userId?: string;
}

export interface ParsedMessage {
  requires2fa?: boolean;
  tempToken?: string;
  id?: number | string;
  msg?: string;
}

export const isParsedMessage = (value: unknown): value is ParsedMessage => {
  if (typeof value !== "object" || value === null) {
    return false;
  }
  const obj = value as Record<string, unknown>;
  return (
    ("requires2fa" in obj ? typeof obj.requires2fa === "boolean" : true) &&
    ("tempToken" in obj ? typeof obj.tempToken === "string" : true) &&
    ("msg" in obj ? typeof obj.msg === "string" : true) &&
    ("id" in obj ? (typeof obj.id === "string" || typeof obj.id === "number") : true)
  );
};

export const parseJsonMessage = (message: string): ParsedMessage | null => {
  try {
    const parsed: unknown = JSON.parse(message);
    if (isParsedMessage(parsed)) {
      return parsed;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const isMessageArray = (message: unknown): message is string[] => {
  return Array.isArray(message) && message.every(item => typeof item === "string");
};