import React, { FC } from "react";
import * as styles from "../popup.module.scss";
import * as authStyles from "../../../Authentication/authentication.module.scss";
import { muiStylesPopup } from "../muistylesPopup";
import { I18n } from "react-redux-i18n";
import { Dialog, DialogContent, DialogTitle, TextField, Button, Typography, Box, styled } from "@mui/material";
import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import { commonFormBase, fontColorSecondary } from "../../../../styles/mui/theme";

const ButtonsContainer = styled(Box)({
  "& .MuiButton-root": {
    marginRight: 0,
  }
});

interface TwoFactorPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  twoFactorCode: string;
  backupCode: string;
  twoFactorError: string;
  showBackupCode: boolean;
  onTwoFactorCodeChange: (value: string) => void;
  onBackupCodeChange: (value: string) => void;
  onToggleBackupCode: () => void;
}

const TwoFactorPopup: FC<TwoFactorPopupProps> = ({
  isOpen,
  onClose,
  onSubmit,
  twoFactorCode,
  backupCode,
  twoFactorError,
  showBackupCode,
  onTwoFactorCodeChange,
  onBackupCodeChange,
  onToggleBackupCode,
}) => {
  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          onClose();
        }
      }}
      PaperProps={{
        sx: {
          ...muiStylesPopup.dialogPaper,
          maxWidth: "500px",
          width: "100%"
        }
      }}
    >
      <DialogTitle sx={muiStylesPopup.dialogHeader}>
        <div style={{ textAlign: "center" }}>
          <h1>{I18n.t("TwoFactor.verifyTitle")}</h1>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{
          ...muiStylesPopup.dialogContent,
          "& .MuiButton-root": { margin: 0 }
        }}
      >
        <div role="dialog">
          {!showBackupCode ? (
            <>
              <Typography style={{ textAlign: "center", marginBottom: "16px" }}>
                {I18n.t("TwoFactor.enterCode")}
              </Typography>
              <TextField
                fullWidth
                value={twoFactorCode}
                onChange={(e) => onTwoFactorCodeChange(e.target.value)}
                error={!!twoFactorError}
                helperText={twoFactorError}
                label={I18n.t("TwoFactor.verificationCode")}
                margin="normal"
                sx={{ ...commonFormBase.input, width: "100%" }}
              />
              <Button
                onClick={onToggleBackupCode}
                style={{ textDecoration: "none", color: fontColorSecondary, cursor: "pointer" }}
              >
                {I18n.t("TwoFactor.useBackupCode")}
              </Button>
            </>
          ) : (
            <>
              <Typography style={{ textAlign: "center", marginBottom: "16px" }}>
                {I18n.t("TwoFactor.enterBackupCode")}
              </Typography>
              <TextField
                fullWidth
                value={backupCode}
                onChange={(e) => onBackupCodeChange(e.target.value)}
                error={!!twoFactorError}
                helperText={twoFactorError}
                label={I18n.t("TwoFactor.backupCode")}
                margin="normal"
                sx={{ ...commonFormBase.input, width: "100%" }}
              />
              <Button
                onClick={onToggleBackupCode}
                style={{ textDecoration: "none", color: fontColorSecondary, cursor: "pointer" }}
              >
                {I18n.t("TwoFactor.useAuthenticator")}
              </Button>
            </>
          )}
        </div>
        <ButtonsContainer className={styles.buttonsContainerSpaceBetween}>
          <SecondaryButton
            text={I18n.t("Buttons.back")}
            event={onClose}
          />
          <PrimaryButton
            text={I18n.t("Buttons.next")}
            event={onSubmit}
            disabled={showBackupCode ? !backupCode : !twoFactorCode}
          />
        </ButtonsContainer>
      </DialogContent>
    </Dialog>
  );
};

export default TwoFactorPopup;