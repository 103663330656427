// extracted by mini-css-extract-plugin
export var h1Typography = "cookiesPopup-module--h1-typography--id2g4";
export var header = "cookiesPopup-module--header--J1Vbm";
export var h2Typography = "cookiesPopup-module--h2-typography--BjeIy";
export var h2TypographySecond = "cookiesPopup-module--h2-typography-second--vN4xu";
export var h3Typography = "cookiesPopup-module--h3-typography--EnhoO";
export var h3TypographyError = "cookiesPopup-module--h3-typography-error--q6wqS";
export var h4Typography = "cookiesPopup-module--h4-typography--0v9nd";
export var pTypography = "cookiesPopup-module--p-typography--QY7S5";
export var contentContainer = "cookiesPopup-module--content-container--Pn+kR";
export var contentContainerDelete = "cookiesPopup-module--content-container-delete--vBgS5";
export var cookiesContentContainer = "cookiesPopup-module--cookies-content-container--6VI2S";
export var smallPTypography = "cookiesPopup-module--small-p-typography--0sj+q";
export var sidebarTypography = "cookiesPopup-module--sidebar-typography--4JDW+";
export var errorTypography = "cookiesPopup-module--error-typography--KPAVL";
export var captionTypography = "cookiesPopup-module--caption-typography--hFS1z";
export var authMessageLabelTypography = "cookiesPopup-module--auth-message-label-typography--LVelU";
export var authMessageTypography = "cookiesPopup-module--auth-message-typography--zcgUu";
export var versionInfoTypography = "cookiesPopup-module--version-info-typography--FEuS0";
export var itemHidden = "cookiesPopup-module--item-hidden--xhsDv";
export var ___gatsby = "cookiesPopup-module--___gatsby--hFYGV";
export var gatsbyFocusWrapper = "cookiesPopup-module--gatsby-focus-wrapper--Z07Hl";
export var info = "cookiesPopup-module--info--XMpR+";
export var buttonsContainer = "cookiesPopup-module--buttons-container--Nhz2S";
export var buttonsContainerDelete = "cookiesPopup-module--buttons-container-delete--arEr6";
export var buttonsContainerSpaceBetween = "cookiesPopup-module--buttons-container-space-between--csFjK";
export var spaceBetweenButtons = "cookiesPopup-module--space-between-buttons--0fV89";
export var jsonContent = "cookiesPopup-module--json-content--zHMHO";